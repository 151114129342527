import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-61af2d00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PosTicketCard = _resolveComponent("PosTicketCard")!
  const _component_OrderList = _resolveComponent("OrderList")!

  return (_openBlock(), _createBlock(_component_OrderList, {
    selection: _ctx.modelValue,
    "model-value": _ctx.tickets,
    dataKey: "id",
    onSelectionChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.value))),
    class: "ticket-container"
  }, {
    header: _withCtx(() => [
      _createElementVNode("h5", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    item: _withCtx(({ item }) => [
      _createVNode(_component_PosTicketCard, { item: item }, null, 8, ["item"])
    ]),
    _: 3
  }, 8, ["selection", "model-value"]))
}